<template>
  <base-section
    id="we-help-your-success"
  >
    <base-section-heading
      title="HOWELLCARE INDUSTRIES: KEY PERSONNEL"
    />
    <v-img
      :src="require('@/assets/members_last.png')"
    />
    <br>
    <br>
    <br>
    <br>
    <base-section-heading
      title="HOWELLCARE INDUSTRIES: our team"
    />
    <v-container>
      <v-row>
        <v-col
          v-for="(info, n) in information"
          :key="n"
          cols="6"
          md="3"
          sm="3"
        >
          <v-hover>
            <template #default="{ hover }">
              <base-img
                :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
                :src="info.src"
                color="red"
                flat
                tile
              >
                <v-overlay
                  :value="hover"
                  absolute
                  class="pa-4"
                  color="secondary"
                  opacity=".9"
                >
                  <base-subheading
                    size="text-h5"
                    mobile-size="text-h6"
                    :title="info.title"
                    class="text-center"
                  />
                  <base-subheading
                    size="text-h6"
                    mobile-size="text-body-1"
                    :title="info.name"
                    class="text-center"
                  />
                </v-overlay>
              </base-img>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionWeHelpYourSuccess',
    data () {
      return {
        information: [
          {
            title: 'HR & ADMIN',
            name: 'Abdul Jamil Mokhtar',
            text: '· HUMAN RESOURCE MANAGEMENT & OPERATIONS AND ADMINISTRATION</br>· CONTRACTS, REGULATIONS, PUBLIC RELATIONS AND LIAISON (LOCAL AND FEDERAL GOVERNMENT)',
            src: require('@/assets/JAMIL-HR ADMIN.jpg'),
          },
          {
            title: 'PROCUREMENT',
            name: 'Chong Wen Bin',
            text: '· STRATEGIC PROCUREMENT</br>· PLANNING</br>· MARKETING COMMUNICATION',
            src: require('@/assets/CHONG WEN BIN-PROCUREMENT.jpg'),
          },
          {
            title: 'CIVIL AND MECHANICAL ENGINEERING',
            name: 'Christopher Lim',
            text: '· CIVIL AND MECHANICAL ENGINEERING SOLUTIONS</br>· DESIGN, ENGINEERING AND CONSTRUCTION TECHNICAL SOLUTIONS',
            src: require('@/assets/CHRISTOPHER- ENGINEERING.jpg'),
          },
          {
            title: 'MARKETING',
            name: 'Esther Khong',
            text: '· ELECTRICAL ENGINEERING SOLUTIONS</br>· UTILITY MANAGEMENT AND OPERATION',
            src: require('@/assets/ESTHER-MARKETING.jpg'),
          },
          {
            title: 'SAFETY, HEALTH & ENVIRONMENT',
            name: 'Nithiyanandhan',
            text: '· SAFETY MANAGEMENT, CONTROL AND INSPECTION REGULATIONS</br>· TRAINING AND AWARENESS',
            src: require('@/assets/NITHIYA-SAFETY.jpg'),
          },
          {
            title: 'QA & QC',
            name: 'Abdul Rahman Zakaria',
            text: '· PROJECT QC</br>· PROJECT CONTROL</br>· PROJECT COORDINATION',
            src: require('@/assets/RAHMAN-QA.jpg'),
          },
          {
            title: 'LOGISTICS & LOCALITY',
            name: 'Mohd Sufhairi',
            text: '· LOGISTIC COORDINATION, CONTROL AND PLANNING',
            src: require('@/assets/SUFHAIRI- LOGISTICS.jpg'),
          },
          {
            title: 'ACCOUNTS & FINANCE',
            name: 'Leow Siao Wei',
            text: '· ACCOUNTING</br>· FINANCIAL MANAGEMENT</br>· PAYROLL MANAGEMENT',
            src: require('@/assets/Leow Siao Wei.jpg'),
          },
          {
            title: 'BUSINESS DEVELOPMENT',
            name: 'ERIC KOO',
            text: '· ACCOUNTING</br>· FINANCIAL MANAGEMENT</br>· PAYROLL MANAGEMENT',
            src: require('@/assets/ERIC KOO- BUSINESS DEVELOPMENT.jpg'),
          },
        ],
      }
    },
  }
</script>
